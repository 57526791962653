/* ======================================================================== */
/* 71. syncAttributes */
/* ======================================================================== */
function syncAttributes($sourceElement, $targetElement) {

	if (!$sourceElement.length || !$targetElement.length) {
		return;
	}

	var sourceAttrs = $sourceElement.getAllAttributes();

	$targetElement.attr(sourceAttrs);

}
